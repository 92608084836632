import React from 'react'
import styled from 'styled-components'
import { ArrowForward } from '@material-ui/icons'

import { IHomeBrandsBlock } from '../../../models/home/types'
import FadeInSection from '../../fade-in'

interface IProps {
  content: IHomeBrandsBlock
}

export default function BrandsBlock({ content: { brands } }: IProps) {
  return (
    <FadeInSection>
      <Container>
        {brands &&
          brands.map((brand, index) => (
            <BrandContainer background={brand.background ? brand.background.url : undefined} key={index}>
              <img alt={brand.logo.alt} className={index === 3 ? 'last' : ''} src={brand.logo.url} />
              <div dangerouslySetInnerHTML={{ __html: brand.title }} />
              <div className="hover">
                <a href={brand.linkUrl} target="_blank">
                  {brand.subtitle}
                  <ArrowForward color="secondary" />
                </a>
              </div>
            </BrandContainer>
          ))}
      </Container>
    </FadeInSection>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;
  margin-bottom: 120px;
  width: 100%;

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

const BrandContainer = styled.div<{ background?: string }>`
  align-items: center;
  background: ${({ background }) => (background ? `url("${background}") center center` : 'none')};
  background-size: cover;
  border-radius: 18.6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 34px 44px;
  position: relative;
  transition: transform 0.2s linear;
  @media screen and (max-width: 800px) {
    box-shadow: 0 6px 24px 0 rgba(74, 74, 74, 0.2);
    padding: 30px;
    height: 184px;
  }

  & > div.hover {
    opacity: 0;
    @media screen and (max-width: 800px) {
      opacity: 1;
      position: absolute;
      bottom: 0px;
    }

    & > a {
      align-items: center;
      display: flex;
      font-family: 'Proxima Nova Extrabold', sans-serif;
      color: ${({ theme }) => theme.palette.secondary.main};
      font-size: 16px;
      & > svg {
        margin-left: 6px;
      }
    }
  }

  &:hover {
    box-shadow: 0 6px 24px 0 rgba(74, 74, 74, 0.2);
    transform: scale(1.1);
    & > div.hover {
      opacity: 1;
      position: absolute;
      bottom: 0;
    }
  }

  & > img {
    height: 16px;
    width: auto;
    &.last {
      height: 22px;
    }
    @media screen and (max-width: 800px) {
      height: 24px;
    }
  }
  & > div {
    margin: 21px auto;
    & > h4 {
      font-family: 'Proxima Nova', sans-serif;
      font-size: 16px;
      line-height: 18px;
      margin: 0;
      text-align: center;
      @media screen and (max-width: 800px) {
        & > br {
          display: none;
        }
      }
    }
  }
`
