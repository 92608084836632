import React from 'react'

import {
  HomeSliceType,
  IHomeBanner,
  IHomeBrandsBlock,
  IHomeEdTechBlock,
  IHomeInlineBlock,
  IHomeMainTitleBlock,
  IHomeProjectCards,
  IHomeVideo,
  IHomeSlice,
  IHomeFullScreenParallax,
} from '../../../models/home/types'

import Banner from './banner'
import BrandsBlock from './brands-block'
import EdTechBlock from './edtech-block'
import InlineBlock from './inline-block'
import MainTitleBlock from './main-title-block'
import ProjectCards from './project-cards'
import Video from './video'
import FullScreenParallax from './fullscreen-parallax'

interface IProps {
  data: IHomeSlice[]
  english?: boolean
}

export default function Slices({ data, english }: IProps) {
  return (
    <>
      {data &&
        data.map(({ type, content }, index) => {
          switch (type) {
            case HomeSliceType.MAIN_TITLE_BLOCK:
              return <MainTitleBlock content={content as IHomeMainTitleBlock} english={english} key={index} />
            case HomeSliceType.INLINE_BLOCK:
              return <InlineBlock content={content as IHomeInlineBlock} english={english} key={index} />
            case HomeSliceType.BANNER:
              return <Banner content={content as IHomeBanner} key={index} />
            case HomeSliceType.EDTECH_BLOCK:
              return <EdTechBlock content={content as IHomeEdTechBlock} key={index} />
            case HomeSliceType.PROJECT_CARDS:
              return <ProjectCards content={content as IHomeProjectCards} key={index} />
            case HomeSliceType.BRANDS_BLOCK:
              return <BrandsBlock content={content as IHomeBrandsBlock} key={index} />
            case HomeSliceType.VIDEO:
              return <Video content={content as IHomeVideo} key={index} />
            case HomeSliceType.FULLSCREEN_PARALLAX:
              return <FullScreenParallax content={content as IHomeFullScreenParallax} key={index} />
            default:
              return null
          }
        })}
    </>
  )
}
