/* eslint-disable complexity */
/* eslint-disable unicorn/no-for-loop */
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useWindowWidth } from '../../../hooks/window-width'
import { IHomeEdTechBlock } from '../../../models/home/types'

interface IProps {
  content: IHomeEdTechBlock
}

export default function EdTechBlock({ content: { left, right } }: IProps) {
  const windowWidth = useWindowWidth()

  function handleScroll() {
    const container = window.document.querySelector('#ed-tech-container')
    const leftBlock = window.document.querySelector('#left')
    const rightBlock = window.document.querySelector('#right')
    const middleBlock = window.document.querySelector('#middle')

    if (
      container &&
      leftBlock &&
      rightBlock &&
      middleBlock &&
      leftBlock.children[1] &&
      leftBlock.children[1].children &&
      rightBlock.children[1] &&
      rightBlock.children[1].children
    ) {
      const leftRows = leftBlock.children[1].children
      const rightRows = rightBlock.children[1].children

      // @ts-ignore
      if (window.scrollY < container.offsetTop - window.innerHeight / 2) {
        rightBlock.classList.add('hidden')
        rightBlock.classList.remove('show')
        leftBlock.classList.add('hidden')
        leftBlock.classList.remove('show')
        middleBlock.classList.add('hidden')
        middleBlock.classList.remove('show')
        console.log(leftRows)
        for (const leftRow of leftRows) {
          if (leftRow) {
            leftRow.classList.add('hidden')
            leftRow.classList.remove('show')
          }
        }
        for (const rightRow of rightRows) {
          if (rightRow) {
            rightRow.classList.add('hidden')
            rightRow.classList.remove('show')
          }
        }
        // @ts-ignore
      } else if (window.scrollY < container.offsetTop - 64 + 200) {
        rightBlock.classList.remove('hidden')
        rightBlock.classList.add('show')
        leftBlock.classList.remove('hidden')
        leftBlock.classList.add('show')
        middleBlock.classList.remove('hidden')
        middleBlock.classList.add('show')
        for (let i = 0; i < leftRows.length; i++) {
          if (leftRows[i] && leftRows[i].classList.contains('hidden')) {
            setTimeout(() => {
              leftRows[i].classList.remove('hidden')
              leftRows[i].classList.add('show')
            }, 800 + i * 200)
          }
        }
        for (let i = 0; i < rightRows.length; i++) {
          if (rightRows[i] && rightRows[i].classList.contains('hidden')) {
            setTimeout(() => {
              rightRows[i].classList.remove('hidden')
              rightRows[i].classList.add('show')
            }, 900 + i * 200)
          }
        }
      }
    }
  }

  useEffect(() => {
    if (windowWidth > 480) {
      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [windowWidth])

  const leftContent = left.text.slice(3, -4).split('</p><p>')
  const rightContent = right.text.slice(3, -4).split('</p><p>')

  return (
    <Container id="ed-tech-container" onScroll={handleScroll}>
      <div className="hidden" id="left">
        <h3>{left.title}</h3>
        <div>
          {leftContent &&
            leftContent.map((row, index) => (
              <p className="hidden" key={index}>
                {row}
              </p>
            ))}
        </div>
      </div>
      <div className="hidden" id="middle">
        +
      </div>
      <div className="hidden" id="right">
        <h3>{right.title}</h3>
        <div>
          {rightContent &&
            rightContent.map((row, index) => (
              <p className="hidden" key={index}>
                {row}
              </p>
            ))}
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 200px;
  padding: 96px 0;
  width: 100vw;

  @media screen and (max-width: 480px) {
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 32px 16px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    transition: opacity 0.6s ease-in-out 0.1s, transform 0.8s ease-in-out;

    &#left {
      &.show {
        opacity: 1;
        transform: translate(0);
      }

      &.hidden {
        opacity: 0;
        transform: translate(-100%);

        @media screen and (max-width: 480px) {
          opacity: 1;
          transform: none;
        }
      }

      & > div > p {
        transition: opacity 0.6s ease-in-out 0.1s;

        &.show {
          opacity: 1;
        }

        &.hidden {
          opacity: 0;
        }
      }
    }
    &#middle {
      &.show {
        opacity: 1;
        transform: translate(0, 0);
      }

      &.hidden {
        opacity: 0;
        transform: translate(0, 20px);
        @media screen and (max-width: 480px) {
          opacity: 1;
          transform: none;
        }
      }
    }
    &#right {
      &.show {
        opacity: 1;
        transform: translate(0);
      }

      &.hidden {
        opacity: 0;
        transform: translate(100%);
        @media screen and (max-width: 480px) {
          opacity: 1;
          transform: none;
        }
      }

      & > div > p {
        transition: opacity 0.6s ease-in-out 0.1s;

        &.show {
          opacity: 1;
        }

        &.hidden {
          opacity: 0;
        }
      }
    }

    & > h3 {
      color: ${({ theme }) => theme.palette.secondary.main};
      font-family: 'Proxima Nova Extrabold', sans-serif;
      font-size: 80px;

      @media screen and (max-width: 480px) {
        font-size: 48px;
      }
    }

    & > div {
      text-transform: uppercase;
      font-size: 16px;

      @media screen and (max-width: 480px) {
        font-size: 12px;
      }
    }
  }

  & > div:nth-child(1) {
    align-items: flex-end;
    justify-content: flex-start;
    width: calc(100% - 160px);

    @media screen and (max-width: 480px) {
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
    }

    & > div {
      text-align: right;

      @media screen and (max-width: 480px) {
        text-align: center;
      }
    }
  }

  & > div:nth-child(2) {
    align-items: center;
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 80px;
    justify-content: center;
    padding: 0 64px;

    @media screen and (max-width: 480px) {
      font-size: 48px;
      padding: 16px 0;
    }
  }

  & > div:nth-child(3) {
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(100% - 160px);

    @media screen and (max-width: 480px) {
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
    }
  }
`
