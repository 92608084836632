import React from 'react'
import styled from 'styled-components'

import { IHomeVideo } from '../../../models/home/types'
import { getYouTubeEmbed } from '../../../utils/cookies'
import FadeInSection from '../../fade-in'

interface IProps {
  content: IHomeVideo
}

export default function Video({ content: { embed, description } }: IProps) {
  return (
    <FadeInSection fullWidth>
      <Container>
        <iframe
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          src={`${getYouTubeEmbed(embed)}?rel=0&showinfo=0`}
          title={`${embed}?rel=0&showinfo=0`}
        />
        {description && <p>{description}</p>}
      </Container>
    </FadeInSection>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 112px;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;

  @media screen and (max-width: 480px) {
    margin: 16px auto 64px;
    width: 100%;
  }

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > p {
    font-family: 'Proxima Nova Light Italic', sans-serif;
    font-size: 12px;
    margin: 4px 0;
    width: 100%;
  }
`
