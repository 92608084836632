import React from 'react'

export default function SvgPlay() {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z"
        fill="#4A4A4A"
        fillRule="evenodd"
        transform="translate(-795 -456) translate(319 184) translate(0 264) translate(295) translate(32 8) translate(149)"
      />
    </svg>
  )
}
