import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { IHomeInlineBlock } from '../../../models/home/types'
import FadeInSection from '../../fade-in'
import Button from '../../buttons/main'

interface IProps {
  content: IHomeInlineBlock
  english?: boolean
}

export default function InlineBlock({ content: { title, subtitle, text, link }, english }: IProps) {
  return (
    <FadeInSection fullWidth>
      <Container>
        <div>
          <h6>{subtitle}</h6>
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: text }} />
          {link &&
            (english ? (
              <a href={link.url} target="_blank">
                <Button color="secondary" variant="contained" width="auto">
                  {link.label}
                </Button>
              </a>
            ) : (
              <Link to={link.url}>
                <Button color="secondary" variant="contained" width="auto">
                  {link.label}
                </Button>
              </Link>
            ))}
        </div>
      </Container>
    </FadeInSection>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 120px;
  width: 100%;

  @media screen and (max-width: 480px) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 32px;
  }

  & > div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(50% - 16px);

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    & > h6 {
      font-size: 14px;
      text-transform: uppercase;
      opacity: 0.8;
    }

    & > div {
      margin-top: 8px;
      max-width: 520px;
      & > h2 {
        color: ${({ theme }) => theme.palette.primary.main};
        font-family: 'Proxima Nova Extrabold', sans-serif;
        font-size: 40px;
        line-height: 48px;
        margin: 0;

        @media screen and (max-width: 800px) {
          & > br {
            display: none;
          }
        }

        @media screen and (max-width: 480px) {
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 16px;
          max-width: 100%;
        }
      }
    }

    & > p {
      font-size: 16px;
      margin-left: 16px;
      min-height: 16px;

      @media screen and (max-width: 480px) {
        margin-left: 0;
      }
    }
  }

  & > div:nth-child(2) {
    border-left: ${({ theme }) => `solid 3px ${theme.palette.secondary.main}`};

    @media screen and (max-width: 480px) {
      border-left: none;
    }

    & > div {
      padding-left: 16px;
      @media screen and (max-width: 480px) {
        padding-left: 0;
      }
    }

    & > a {
      margin: 16px 0 0 16px;

      & > button {
        padding: 16px 24px;

        @media screen and (max-width: 480px) {
          width: 100%;
        }
      }

      @media screen and (max-width: 480px) {
        margin: 16px 0 0 0;
        width: 100%;
      }
    }
  }
`
