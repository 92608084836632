import { IImage, ILink } from '../../types/custom'

export interface IHomeContent {
  name: string
}

export interface IHomeMainTitleBlock {
  title: string
  subtitle: string
  leftLink?: ILink
  rightLink?: ILink
}

export interface IHomeInlineBlock {
  title: string
  subtitle: string
  text: string
  link?: ILink
}

export interface IHomeFullScreenParallax {
  background?: IImage
  description?: string
  mainText?: string
}

export interface IHomeBanner {
  videoUrl: string
}

export interface IHomeEdTechBlock {
  left: {
    title: string
    text: string
  }
  right: {
    title: string
    text: string
  }
}

interface IHomeCard {
  title: string
  subtitle: string
  background: IImage
  linkUrl?: string
}

export interface IHomeProjectCards {
  left: {
    title: string
    subtitle: string
    text: string
  }
  right: {
    text: string
    link?: ILink
  }
  cards: IHomeCard[]
}

export interface IHomeBrandsBlock {
  brands: Array<{
    logo: IImage
    title: string
    subtitle: string
    background?: IImage
    linkUrl: string
  }>
}

export interface IHomeVideo {
  embed: string
  description?: string
}

export interface IHomeSlice {
  type: HomeSliceType
  content:
    | IHomeMainTitleBlock
    | IHomeInlineBlock
    | IHomeBanner
    | IHomeEdTechBlock
    | IHomeProjectCards
    | IHomeBrandsBlock
    | IHomeVideo
    | IHomeFullScreenParallax
}

export enum HomeSliceType {
  MAIN_TITLE_BLOCK = 'MAIN_TITLE_BLOCK',
  INLINE_BLOCK = 'INLINE_BLOCK',
  BANNER = 'BANNER',
  EDTECH_BLOCK = 'EDTECH_BLOCK',
  PROJECT_CARDS = 'PROJECT_CARDS',
  BRANDS_BLOCK = 'BRANDS_BLOCK',
  VIDEO = 'VIDEO',
  FULLSCREEN_PARALLAX = 'FULLSCREEN_PARALLAX',
}
