import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useWindowWidth } from '../../../hooks/window-width'
import { IHomeBanner } from '../../../models/home/types'
import FadeInSection from '../../fade-in'

interface IProps {
  content: IHomeBanner
}

export default function Banner({ content: { videoUrl } }: IProps) {
  const windowWidth = useWindowWidth()

  function handleScroll() {
    const container = window.document.querySelector('#image')

    if (container) {
      if (window.scrollY < container.offsetTop - window.innerHeight / 2) {
        container.style.width = '100%'
      } else if (window.scrollY < container.offsetTop - 64) {
        const ratio =
          (window.scrollY - (container.offsetTop - window.innerHeight / 2)) /
          (container.offsetTop - 64 - (container.offsetTop - window.innerHeight / 2))
        container.style.width = `calc(100% + (100vw - 100%) * ${ratio})`
      } else {
        container.style.width = '100vw'
      }
    }
  }

  useEffect(() => {
    if (windowWidth > 480) {
      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [windowWidth])

  return (
    <Container onScroll={handleScroll}>
      <div id="image">
        <FadeInSection>
          <video autoPlay loop muted playsInline id="video-slideshow" src={videoUrl} />
        </FadeInSection>
      </div>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 680px;
  justify-content: center;
  margin-bottom: 200px;
  width: 100%;

  @media screen and (max-width: 480px) {
    height: 224px;
    margin-bottom: 32px;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 680px;
    justify-content: center;
    overflow: hidden;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 224px;
    }

    & > div > video {
      height: 100%;
      max-height: 100%;
      object-fit: fill;
      width: auto;

      @media screen and (max-width: 768px) {
        height: 400px;
      }

      @media screen and (max-width: 480px) {
        height: 200px;
      }
    }
  }
`
