import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import FadeInSection from '../../fade-in'
import { IHomeMainTitleBlock } from '../../../models/home/types'
import Button from '../../buttons/main'
import SvgPlay from '../../svg/play'

interface IProps {
  content: IHomeMainTitleBlock
  english?: boolean
}

export default function MainTitleBlock({ content: { title, subtitle, leftLink, rightLink }, english }: IProps) {
  return (
    <FadeInSection fullWidth>
      <Container>
        <h6>{subtitle}</h6>
        <div
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <div>
          {leftLink && (
            <Button color="secondary" variant="contained" width="auto">
              <Link to={leftLink.url}>{leftLink.label}</Link>
            </Button>
          )}
          {rightLink &&
            (english ? (
              <Button color="secondary" variant="outlined" width="auto">
                <a href={rightLink.url} target="_blank">
                  <span>{rightLink.label}</span>
                  <SvgPlay />
                </a>
              </Button>
            ) : (
              <Button color="secondary" variant="outlined" width="auto">
                <Link to={rightLink.url}>
                  <span>{rightLink.label}</span>
                  <SvgPlay />
                </Link>
              </Button>
            ))}
        </div>
      </Container>
    </FadeInSection>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 200px;
  width: 100%;

  @media screen and (max-width: 480px) {
    margin-bottom: 32px;
  }

  & > h6 {
    font-size: 14px;
    opacity: 0.8;
    text-transform: uppercase;
  }

  & > div > h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 52px;
    margin-top: 8px;
    max-width: 758px;

    & > span.no-blank {
      white-space: nowrap;

      @media screen and (max-width: 480px) {
        white-space: normal;
      }
    }

    @media screen and (max-width: 480px) {
      font-size: 34px;
    }
  }

  & > div {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 32px;
    width: 100%;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      margin-top: 16px;

      & > button {
        margin: 8px 0;
        width: 100%;
      }
    }

    & > button > a {
      align-items: center;
      color: ${({ theme }) => theme.palette.text.secondary};
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      padding: 0 32px;

      @media screen and (max-width: 480px) {
        padding: 0;
      }

      & > span {
        color: ${({ theme }) => theme.palette.secondary.main};
      }

      & > svg {
        height: 24px;
        width: 24px;
        margin-left: 8px;

        & > path {
          fill: ${({ theme }) => theme.palette.secondary.main};
        }
      }
    }

    & > button:nth-child(2) {
      margin-left: 16px;

      @media screen and (max-width: 480px) {
        margin-left: 0;
      }
    }
  }
`
