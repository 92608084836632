import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { IHomeProjectCards } from '../../../models/home/types'
import Button from '../../buttons/main'
import SvgArrowRight from '../../svg/arrow-right'
import FadeInSection from '../../fade-in'

interface IProps {
  content: IHomeProjectCards
}

export default function ProjectCards({ content: { left, right, cards } }: IProps) {
  const half = Math.ceil(cards.length / 2)
  const firstHalf = cards.splice(0, half)
  const secondHalf = cards.splice(-half)

  return (
    <FadeInSection>
      <Container>
        <div>
          <div>
            <TitleContainer>
              <h6>{left.subtitle}</h6>
              <h2>{left.title}</h2>
            </TitleContainer>
            <div dangerouslySetInnerHTML={{ __html: left.text }} />
            {cards &&
              firstHalf.map((card, index) =>
                card.linkUrl ? (
                  <CardLinkContainer background={card.background.url} key={index} to={card.linkUrl}>
                    <div>
                      <div>
                        <h3>{card.title}</h3>
                        <p>{card.subtitle}</p>
                      </div>
                      <div>
                        <SvgArrowRight />
                      </div>
                    </div>
                  </CardLinkContainer>
                ) : (
                  <CardContainer background={card.background.url} key={index}>
                    <div>
                      <div>
                        <h3>{card.title}</h3>
                        <p>{card.subtitle}</p>
                      </div>
                      <div>
                        <SvgArrowRight />
                      </div>
                    </div>
                  </CardContainer>
                )
              )}
          </div>
          <div>
            {cards &&
              secondHalf.map((card, index) =>
                card.linkUrl ? (
                  <CardLinkContainer background={card.background.url} key={index} to={card.linkUrl}>
                    <div>
                      <div>
                        <h3>{card.title}</h3>
                        <p>{card.subtitle}</p>
                      </div>
                      <div>
                        <SvgArrowRight />
                      </div>
                    </div>
                  </CardLinkContainer>
                ) : (
                  <CardContainer background={card.background.url} key={index}>
                    <div>
                      <div>
                        <h3>{card.title}</h3>
                        <p>{card.subtitle}</p>
                      </div>
                      <div>
                        <SvgArrowRight />
                      </div>
                    </div>
                  </CardContainer>
                )
              )}
            <Div className="bottom" dangerouslySetInnerHTML={{ __html: right.text }} />
            {right.link && (
              <Button color="secondary" variant="contained" width="auto">
                <Link to={right.link.url}>{right.link.label}</Link>
              </Button>
            )}
          </div>
        </div>
      </Container>
    </FadeInSection>
  )
}

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.text.secondary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 200px;
  padding: 96px 0;
  width: 100vw;

  @media screen and (max-width: 480px) {
    margin-bottom: 32px;
    padding: 32px 16px;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    width: 1280px;

    @media screen and (max-width: 1280px) {
      width: 90%;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;
      justify-content: center;
    }

    & > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: calc(50% - 40px);

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      & div:not(.bottom) > p {
        font-family: 'Proxima Nova', sans-serif;
        margin: 24px 0 48px 0;
        font-size: 16px;
        text-align: left;

        @media screen and (max-width: 768px) {
          margin: 16px 0;
          padding-right: 0;
        }
      }

      & > button {
        align-items: center;
        color: ${({ theme }) => theme.palette.text.secondary};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 16px;
        padding: 0 32px;

        & > a {
          color: ${({ theme }) => theme.palette.text.secondary};
          width: 100%;
        }
      }
    }
  }
`

const TitleContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 16px;
  width: 100%;

  @media screen and (max-width: 768px) {
    border-left: none;
    margin-bottom: 32px;
    margin-bottom: 0;
  }

  & > h6 {
    font-size: 14px;
    text-transform: uppercase;
  }

  & > h2 {
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 40px;
    margin-top: 8px;

    @media screen and (max-width: 480px) {
      font-size: 24px;
      line-height: 28px;
    }
  }
`

const CardContainer = styled.div<{ background: string }>`
  background: ${({ background }) => `url("${background}") center center`};
  background-size: cover;
  border-radius: 4px;
  box-shadow: 0 6px 24px 0 rgba(74, 74, 74, 0.2);
  height: 360px;
  margin: 40px 0;
  position: relative;
  transition: all 200ms ease-in;
  width: 100%;

  &:nth-of-type(1) {
    margin: 0px 0px 40px 0px;
    @media screen and (max-width: 768px) {
      margin: 0px 0px;
    }
  }

  @media screen and (max-width: 768px) {
    height: 300px;
  }

  & > div {
    align-items: center;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    bottom: 0;
    border-radius: 0 0 4px 4px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    opacity: 0;
    position: absolute;
    padding: 16px;
    transition: opacity 200ms ease-in;
    width: 100%;

    @media screen and (max-width: 768px) {
      opacity: 1;
    }

    & > div {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > h3 {
        font-family: 'Proxima Nova Extrabold', sans-serif;
        font-size: 32px;
        margin: 4px 0;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      & > p {
        font-size: 16px;
        @media screen and (max-width: 768px) {
          line-height: 20px;
        }
      }

      & > svg {
        height: 32px;
        width: 32px;

        @media screen and (max-width: 480px) {
          height: 24px;
          width: 24px;
        }

        & > path {
          fill: ${({ theme }) => theme.palette.text.secondary};
        }
      }
    }
  }

  :hover {
    border-bottom: none;
    box-shadow: 0 6px 24px 0 rgba(0, 29, 47, 0.5);
    transform: scale(1.02);
    & > div {
      opacity: 1;
    }
  }
`

const CardLinkContainer = styled(Link)<{ background: string }>`
  background: ${({ background }) => `url("${background}") center center`};
  background-size: cover;
  border-radius: 4px;
  box-shadow: 0 6px 24px 0 rgba(74, 74, 74, 0.2);
  cursor: pointer;
  height: 360px;
  margin: 40px 0;
  position: relative;
  transition: all 200ms ease-in;
  width: 100%;

  &:nth-of-type(1) {
    margin: 0px 0px 40px 0px;
    @media screen and (max-width: 768px) {
      margin: 0px 0px;
    }
  }

  @media screen and (max-width: 768px) {
    height: 300px;
  }

  & > div {
    align-items: center;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    bottom: 0;
    border-radius: 0 0 4px 4px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    opacity: 0;
    position: absolute;
    padding: 16px;
    transition: opacity 200ms ease-in;
    width: 100%;

    @media screen and (max-width: 768px) {
      opacity: 1;
    }

    & > div {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > h3 {
        font-family: 'Proxima Nova Extrabold', sans-serif;
        font-size: 32px;
        margin: 4px 0;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      & > p {
        font-size: 16px;
        @media screen and (max-width: 768px) {
          line-height: 20px;
        }
      }

      & > svg {
        height: 32px;
        width: 32px;

        @media screen and (max-width: 480px) {
          height: 24px;
          width: 24px;
        }

        & > path {
          fill: ${({ theme }) => theme.palette.text.secondary};
        }
      }
    }
  }

  :hover {
    border-bottom: none;
    box-shadow: 0 6px 24px 0 rgba(0, 29, 47, 0.5);
    transform: scale(1.02);
    & > div {
      opacity: 1;
    }
  }
`

const Div = styled.div`
  margin: 48px 0 16px 0;

  @media screen and (max-width: 480px) {
    margin: 16px 0;
  }

  & > p {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 40px;
    line-height: 50px;
    margin: 4px 0;
    text-transform: uppercase;
    text-align: center;
    & > span.blue-text {
      color: ${({ theme }) => theme.palette.secondary.main};
    }

    @media screen and (max-width: 480px) {
      font-size: 24px;
      text-align: left;
    }
  }
`
