import React from 'react'
import styled from 'styled-components'
import { Parallax } from 'react-parallax'

import { IHomeFullScreenParallax } from '../../../models/home/types'
// Import FadeInSection from '../../fade-in'

interface IProps {
  content: IHomeFullScreenParallax
}

export default function FullScreenParallax({ content: { background, description, mainText } }: IProps) {
  return (
    <Container>
      <Parallax bgImage={background.url} strength={200}>
        <StyledContent>
          <h2>{mainText}</h2>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </StyledContent>
      </Parallax>
    </Container>
  )
}

const Container = styled.div`
  color: #fff;
  text-align: center;
  width: 100vw;
  margin-top: -160px;
  height: calc(100vh - 52px);
  margin-bottom: 120px;
  @media screen and (max-width: 480px) {
    margin-top: 0;
    margin-bottom: 40px;
    height: 368px;
  }
  .react-parallax {
    height: 100%;
  }
  img {
    height: calc(100vh - 52px);
    @media screen and (max-width: 480px) {
      height: 368px !important;
    }
  }
  .react-parallax-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 64px;
    @media screen and (max-width: 480px) {
      padding-bottom: 48px;
    }
  }
`

const StyledContent = styled.div`
  & > h2 {
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 150px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 10px 44px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 800px) {
      font-size: 100px;
    }

    @media screen and (max-width: 480px) {
      font-size: 56px;
    }
  }
  & > div > p {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 24px;
    font-weight: 500;
    width: 50vw;
    line-height: 1.2;
    margin: 0 auto;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.24), 0 10px 44px rgba(0, 0, 0, 0.5), 0 2px 12px rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 800px) {
      font-size: 24px;
    }
    @media screen and (max-width: 480px) {
      font-size: 16px;
      width: 80vw;
    }
  }
`
