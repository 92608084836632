import React from 'react'
import styled from 'styled-components'

import { IHomeSlice } from '../../models/home/types'

import Slices from './slices'

interface IProps {
  slices: IHomeSlice[]
  english?: boolean
}

export default function Body({ slices, english }: IProps) {
  return (
    <Container>
      <Slices data={slices} english={english} />
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 32px;
  width: 100%;

  @media screen and (max-width: 480px) {
    margin-top: 0;
  }
`
